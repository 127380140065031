import { NavLink} from 'react-router-dom';
import './header.scss';
import logo from './logo.png';
import { useState } from 'react';
import { RxHamburgerMenu } from "react-icons/rx";
import { FiSearch } from "react-icons/fi";


const Header = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSearchVisible, setSearchVisible] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(prev => !prev);
    };
    
    const toggleSearch = () => {
        setSearchVisible(prev => !prev);
    }

    const handleLinkClick = () => {
        setMenuOpen(false); // Закрываем меню
 // Переходим по указанному пути
    }

    return (
        <header className="header">
            <div className="container">
                <div className="header__wrapper">
                    <div className="header__wrapper-top">
                        <img src={logo} alt="logo" />
                        <div className="header__wrapper-descr">
                            <h1>Информационно - новостной онлайн сервис</h1>
                        </div>
                        {isSearchVisible && (  // Условный рендеринг для инпута поиска
                            <input className="search-input" placeholder="Поиск" type="text" />
                        )}
                        <button className='search__button' onClick={toggleSearch}>
                        <FiSearch />
                        </button>
                        
                        <button>Войти</button>
                        <button className="burger" onClick={toggleMenu}>
                        <RxHamburgerMenu />
                        </button>
                    </div>
                    
                    <div className="header__divider"></div>
                    <div className={`header__wrapper-bottom ${isMenuOpen ? 'open' : ''}`}>
                        <NavLink to="/" onClick={() => handleLinkClick("/")}>Главная страница</NavLink>
                        <NavLink to="/credit-card" onClick={() => handleLinkClick("/credit-card")}>Кредитные карты</NavLink>
                        <NavLink to="/debit-card" onClick={() => handleLinkClick("/debit-card")}>Дебетовые карты</NavLink>
                        <NavLink to="/loans" onClick={() => handleLinkClick("/loans")}>Кредиты</NavLink>
                        <NavLink to="/zaim" onClick={() => handleLinkClick("/zaim")}>Займы</NavLink>
                        <NavLink to="/articles" onClick={() => handleLinkClick("/articles")}>Полезные статьи</NavLink>
                        <NavLink to="/questions" onClick={() => handleLinkClick("/questions")}>Вопрос/ответ</NavLink>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;