import { useState, useEffect } from 'react';
import error404 from '../credit/404.jpg';
import loadingsvg from '../credit/loading.svg';
import "./creditCard.scss"
import OneCreditCard from './oneCreditCard/oneCreditCard';

const CreditCard = () => {
    const [originalData, setOriginalData] = useState([]); // Исходные данные
    const [filteredData, setFilteredData] = useState([]); // Отфильтрованные данные
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortOpen, setSortOpen] = useState(false);
    const [sortType, setSortType] = useState('Сортировать');

    // Состояния для инпутов
    const [amount, setAmount] = useState('');
    const [percent, setPercent] = useState('');

    const [filtersOpen, setFiltersOpen] = useState(false);

    const toggleFilters = () => {
        setFiltersOpen(!filtersOpen);
    };


    useEffect(() => {
        fetch('https://comandosfru.xyz/amocrm/db.json')
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok ' + response.statusText);
                return response.json();
            })
            .then(data => {
                setOriginalData(data.cards[0].cards_credit); // Сохранение исходных данных
                setFilteredData(data.cards[0].cards_credit); // Установка начального состояния для отфильтрованных данных
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    const formatNumber = (value) => {
        const sanitizedValue = value.replace(/\D/g, '');
        return sanitizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    const handleAmountChange = (e) => {
        const formattedValue = formatNumber(e.target.value);
        setAmount(formattedValue);
    };

    const handlePercentChange = (e) => {
        let value = e.target.value;
        if (value.includes(',')) {
            value = value.replace(',', '.');
        }
        setPercent(value);
    };

    const handleSearch = () => {
        const filtered = originalData.filter(zaim => {
            const amountNum = parseFloat(amount.replace(/\s/g, ''));
            const percentNum = parseFloat(percent.replace(',', '.'));

            const minSum = parseFloat(zaim.summMin.replace(/\s/g, ''));
            //const maxSum = parseFloat(zaim.summMax.replace(/\s/g, ''));

            return (
                (isNaN(amountNum) || (0 <= amountNum && minSum >= amountNum)) &&
                (isNaN(percentNum) || parseFloat(zaim.percent.replace(',', '.')) <= percentNum)
            );
        });

        setFilteredData(filtered); // Обновляем только отфильтрованные данные
        sortData(sortType, filtered); // Сортируем отфильтрованные данные
    };

    const toggleSortDropdown = () => setSortOpen(!sortOpen);

    const sortData = (type, data) => {
        let sortedData = [...data];

        if (type === 'Сумма по возрастанию') {
            sortedData.sort((a, b) => parseFloat(a.summMin.replace(/\s/g, '')) - parseFloat(b.summMin.replace(/\s/g, '')));
        } else if (type === 'Сумма по убыванию') {
            sortedData.sort((a, b) => parseFloat(b.summMin.replace(/\s/g, '')) - parseFloat(a.summMin.replace(/\s/g, '')));
        } else if (type === 'Проценты по возрастанию') {
            sortedData.sort((a, b) => parseFloat(a.percent.replace(',', '.')) - parseFloat(b.percent.replace(',', '.')));
        } else if (type === 'Проценты по убыванию') {
            sortedData.sort((a, b) => parseFloat(b.percent.replace(',', '.')) - parseFloat(a.percent.replace(',', '.')));
        }

        setFilteredData(sortedData); // Обновляем состояние отфильтрованных данных
    };

    const handleSort = (type) => {
        setSortType(type);
        sortData(type, filteredData);
        setSortOpen(false); // Сортируем уже отфильтрованные данные
    };

    if (loading) return <img src={loadingsvg} alt="Loading" style={{ margin: '0 auto' }} />;
    if (error) return <img src={error404} alt="" />;

    return (
        <div className="creditCard">
            <div className="container">
                <div className="creditCard__wrapper">
                <div className="sort-container">
                    <button onClick={toggleFilters} className="filter-button">
                        Фильтры
                    </button>
                </div>
                {filtersOpen && (
                    <div className="creditCard__wrapper-inputs">
                        <input
                            type="text"
                            placeholder='Укажите сумму:'
                            value={amount}
                            onChange={handleAmountChange}
                        />
                        <input
                            type="text"
                            placeholder='Укажите проценты:'
                            value={percent}
                            onChange={handlePercentChange}
                        />
                        <button onClick={handleSearch}>Подобрать кредитную карту</button>
                    </div>
                )}
                    <div className="sort-container">
                        <h1>Найдено {filteredData.length} предложений</h1>
                        <div className="dropdown">
                            <button onClick={toggleSortDropdown} className="sort-button">
                                {sortType}
                            </button>
                            {sortOpen && (
                                <div className="dropdown-menu">
                                    <button onClick={() => handleSort('Сумма по возрастанию')}>Сумма по возрастанию</button>
                                    <button onClick={() => handleSort('Сумма по убыванию')}>Сумма по убыванию</button>
                                    <button onClick={() => handleSort('Проценты по возрастанию')}>Проценты по возрастанию</button>
                                    <button onClick={() => handleSort('Проценты по убыванию')}>Проценты по убыванию</button>
                                </div>
                            )}
                        </div>
                    </div>
                    {filteredData.map(dat => (
                        <OneCreditCard key={dat.id} dat={dat} />
                    ))}
                </div>
            </div>
        </div>
    );
}


export default CreditCard;