import { useState, useEffect } from 'react';
import OneCredit from '../oneCredit/oneCredit';
import './credit.scss';
import error404 from './404.jpg';
import loadingsvg from './loading.svg';

const Credit = () => {
// Исходные данные
    const [filteredData, setFilteredData] = useState([]); // Отфильтрованные данные
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortOpen, setSortOpen] = useState(false);
    const [sortType, setSortType] = useState('Сортировать');



    useEffect(() => {
        fetch('https://comandosfru.xyz/amocrm/db.json')
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok ' + response.statusText);
                return response.json();
            })
            .then(data => {
// Сохранение исходных данных
                setFilteredData(data.cards[1].cards_debit); // Установка начального состояния для отфильтрованных данных
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    const toggleSortDropdown = () => setSortOpen(!sortOpen);

    const sortData = (type, data) => {
        let sortedData = [...data];

        if (type === 'Сумма по возрастанию') {
            sortedData.sort((a, b) => parseFloat(a.summMax.replace(/\s/g, '')) - parseFloat(b.summMax.replace(/\s/g, '')));
        } else if (type === 'Сумма по убыванию') {
            sortedData.sort((a, b) => parseFloat(b.summMax.replace(/\s/g, '')) - parseFloat(a.summMax.replace(/\s/g, '')));
        } else if (type === 'Проценты по возрастанию') {
            sortedData.sort((a, b) => parseFloat(a.percent.replace(',', '.')) - parseFloat(b.percent.replace(',', '.')));
        } else if (type === 'Проценты по убыванию') {
            sortedData.sort((a, b) => parseFloat(b.percent.replace(',', '.')) - parseFloat(a.percent.replace(',', '.')));
        }

        setFilteredData(sortedData); // Обновляем состояние отфильтрованных данных
    };

    const handleSort = (type) => {
        setSortType(type);
        sortData(type, filteredData);
        setSortOpen(false); // Сортируем уже отфильтрованные данные
    };

    if (loading) return <img src={loadingsvg} alt="Loading" />;
    if (error) return <img src={error404} alt="" />;
    return (
        <div className="credit">
            <div className="container">
                <div className="credit__wrapper">                    
                    <div className="sort-container">
                        <h1>Найдено {filteredData.length} предложений</h1>
                        <div className="dropdown">
                            <button onClick={toggleSortDropdown} className="sort-button">
                                {sortType}
                            </button>
                            {sortOpen && (
                                <div className="dropdown-menu">
                                    <button onClick={() => handleSort('Проценты по возрастанию')}>Проценты по возрастанию</button>
                                    <button onClick={() => handleSort('Проценты по убыванию')}>Проценты по убыванию</button>
                                </div>
                            )}
                        </div>
                    </div>
                    {filteredData.map(dat => (
                        <OneCredit key={dat.id} dat={dat} />
                    ))}
                </div>
            </div>
        </div>
    );
}




export default Credit;