import './oneZaim.scss';

const OneZaim = ({dat}) => {
    return (
        <div className="oneZaim">
                <div className="oneZaim__wrapper">
                    <div className="oneZaim__wrapper-img">
                        <img src={dat.screen} alt="screen" />
                    </div>
                    <div className="oneZaim__wrapper-name">
                        <h2>{dat.name}</h2>
                    </div>
                    <div className="oneZaim__wrapper-money">
                        <h2>{dat.summMid} {dat.summMax} {dat.summPostfix}</h2>
                    </div>
                    <div className="oneZaim__wrapper-percent">
                        <h2>{dat.percentPrefix} {dat.percent} {dat.percentPostfix}</h2>
                    </div>
                    <a href={dat.order} target="_blank" rel="noopener noreferrer">{dat.orderButtonText}</a>
                </div>
        </div>
    )
}

export default OneZaim;