import './checkbox.scss'

const Checkbox = ({ selectedOptions, onFilterChange }) => {
    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
        const updatedOptions = { ...selectedOptions, [id]: checked };
        
        // Обновление состояния чекбоксов и вызов функции фильтрации
        onFilterChange(updatedOptions);
    };

    const checkboxLabels = {
        mastercard: 'Mastercard',
        mir: 'МИР',
        visa: 'Visa',
        qiwi: 'QIWI',
        yandex: 'Яндекс',
        cash: 'Наличные',
    };

    return (
        <div className="checkbox">
            <div className="container">
                <div className="checkbox__wrapper">
                    <h2>Перевод денег</h2>
                    <div className="checkbox__wrapper-divider"></div>
                    <form>
                        {Object.keys(selectedOptions).map(option => (
                            <div className="checkbox-item" key={option}>
                                <input 
                                    id={option}
                                    type="checkbox"
                                    checked={selectedOptions[option]}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor={option}>{checkboxLabels[option]}</label>
                            </div>
                        ))}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Checkbox;