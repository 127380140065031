import './oneCreditCard.scss';

const OneCreditCard = ({dat}) => {
    return (
        <div className="oneCreditCard">
                <div className="oneCreditCard__wrapper">
                    <div className="oneCreditCard__wrapper-img">
                        <img src={dat.screen} alt="screen" />
                    </div>
                    <div className="oneCreditCard__wrapper-name">
                        <h2>{dat.name}</h2>
                    </div>
                    <div className="oneCreditCard__wrapper-money">
                        <h2>{dat.summPrefix} {dat.summMin} {dat.summMid}</h2>
                    </div>
                    <div className="oneCreditCard__wrapper-percent">
                        <h2>{dat.percentPrefix} {dat.percent} {dat.percentPostfix}</h2>
                    </div>
                    <a href={dat.order} target="_blank" rel="noopener noreferrer">{dat.orderButtonText}</a>
                </div>
        </div>
    )
}

export default OneCreditCard;
