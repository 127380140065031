import './homePage.scss';

const HomePage = () => {
    return (
        <div className="homePage">
            <h1>Главная страница</h1>
            <p>Добро пожаловать на наш сайт!</p>
            <p>
                Мы рады приветствовать вас в нашем виртуальном пространстве, где вы найдете все, что нужно для эффективного управления вашими финансами. Наша цель — предоставить вам качественные услуги и актуальные знания, которые помогут вам достигать своих финансовых целей.
            </p>
            <h2>Что мы предлагаем?</h2>
            <p>
                <strong>Кредиты и займы:</strong> Мы предоставляем разнообразные кредитные продукты, которые помогут вам реализовать ваши мечты, будь то покупка жилья, автомобиля или финансирование учебы.
            </p>
            <p>
                <strong>Финансовое планирование:</strong> Наши эксперты помогут вам создать индивидуальный финансовый план, который позволит вам контролировать свои расходы и накапливать средства.
            </p>
            <p>
                <strong>Образовательные ресурсы:</strong> В нашем разделе полезных статей вы найдете актуальные рекомендации и советы по управлению финансами, инвестициям и сбережениям.
            </p>
            <p>
                <strong>Вопросы и ответы:</strong> У нас есть раздел FAQ, где мы собираем ответы на самые распространенные вопросы наших клиентов. Вы всегда можете найти нужную информацию быстро и удобно.
            </p>
            <h2>Почему выбирают нас?</h2>
            <p>
                Мы гордимся высоким уровнем обслуживания клиентов и прозрачностью наших услуг. Наша команда профессионалов всегда готова помочь и ответить на ваши вопросы. Мы ценим каждого клиента и стремимся сделать ваш опыт работы с нами максимально удобным и понятным.
            </p>
            <h2>Контактная информация</h2>
            <p>
                Если у вас есть вопросы или вы хотите узнать больше о наших услугах, не стесняйтесь связаться с нами! Мы здесь, чтобы помочь вам на каждом шагу.
            </p>
            <p>
                Присоединяйтесь к нам на пути к финансовой независимости и стабильности!
            </p>
        </div>
    )
}

export default HomePage;