import './questions.scss';

const Questions = () => {
    return (
        <div className="questions">
            <h1>Вопрос / ответ</h1>
            <p>В этом разделе вы найдете ответы на часто задаваемые вопросы. Если у вас есть дополнительные вопросы, не стесняйтесь обращаться к нашей службе поддержки.</p>
            <p><strong>Вопрос 1: Как я могу открыть счет?</strong></p>
            <p>Ответ: Чтобы открыть счет, вам нужно заполнить анкету на нашем сайте и предоставить необходимые документы для подтверждения вашей личности. После проверки ваших данных вам будет предоставлен доступ к вашему новому счету.</p>
            <hr /><p><strong>Вопрос 2: Какой срок обработки заявок на кредит?</strong></p>
            <p>Ответ: Срок обработки заявок на кредит обычно составляет от 1 до 3 рабочих дней. Мы стараемся обработать вашу заявку как можно быстрее, чтобы вы могли воспользоваться нашими услугами.</p>
            <hr /><p><strong>Вопрос 3: Как узнать мой кредитный лимит?</strong></p>
            <p>Ответ: Вы можете узнать свой кредитный лимит, зайдя в личный кабинет на нашем сайте или позвонив в наш центр поддержки клиентов. Мы также отправим уведомление по электронной почте, когда вы получите кредитный лимит.</p>
            <hr /><p><strong>Вопрос 4: Какие документы нужны для получения кредита?</strong></p>
            <p>Ответ: Для получения кредита вам понадобятся следующие документы:<br />
                - Паспорта и другие удостоверения личности<br />
                - Подтверждение дохода (справка с места работы или налоговая декларация)<br />
                - Документы, подтверждающие ваше финансовое состояние (банковские выписки и т.д.)</p>
            <hr /><p><strong>Вопрос 5: Как вернуть кредит или займ?</strong></p>
            <p>Ответ: Вы можете вернуть кредит или займ через личный кабинет на нашем сайте, прямо в отделении или через систему онлайн-банкинга. Все способы возврата указаны в вашем договоре.</p>
            <hr /><p><strong>Вопрос 6: Как связаться с поддержкой клиентов?</strong></p>
            <p>Ответ: Вы можете связаться с нашей службой поддержки клиентов по телефону, через электронную почту или заполнив формуляр на нашем сайте. Мы отображаем наши контактные данные на странице "Контакты".</p>
            <hr /><p><strong>Заключение</strong></p><p>Надеемся, что вы нашли этот раздел полезным! Если у вас есть дополнительные вопросы или вам нужна помощь, не стесняйтесь обращаться к нам. Мы всегда готовы помочь!</p>
        </div>
    )
}

export default Questions;