import { useState, useEffect } from 'react';
import error404 from '../credit/404.jpg';
import loadingsvg from '../credit/loading.svg';
import './zaim.scss';
import OneZaim from './oneZaim/oneZaim';
import Checkbox from '../checkbox/checkbox';


const Zaim = () => {
    const [originalData, setOriginalData] = useState([]); // Исходные данные
    const [filteredData, setFilteredData] = useState([]); // Отфильтрованные данные
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortOpen, setSortOpen] = useState(false);
    const [sortType, setSortType] = useState('Сортировать');

    // Состояния для инпутов
    const [amount, setAmount] = useState('');
    const [percent, setPercent] = useState('');

    const [selectedOptions, setSelectedOptions] = useState({
        mastercard: false,
        mir: false,
        visa: false,
        qiwi: false,
        yandex: false,
        cash: false,
    });

    useEffect(() => {
        fetch('https://comandosfru.xyz/amocrm/db.json')
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok ' + response.statusText);
                return response.json();
            })
            .then(data => {
                setOriginalData(data.loans); // Сохранение исходных данных
                setFilteredData(data.loans); // Установка начального состояния для отфильтрованных данных
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    const formatNumber = (value) => {
        const sanitizedValue = value.replace(/\D/g, '');
        return sanitizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    const handleAmountChange = (e) => {
        const formattedValue = formatNumber(e.target.value);
        setAmount(formattedValue);
    };

    const handlePercentChange = (e) => {
        let value = e.target.value;
        if (value.includes(',')) {
            value = value.replace(',', '.');
        }
        setPercent(value);
    };

    const handleSearch = () => {
        const filtered = originalData.filter(zaim => {
            const amountNum = parseFloat(amount.replace(/\s/g, ''));
            const percentNum = parseFloat(percent.replace(',', '.'));
        
            const minSum = parseFloat(zaim.summMin.replace(/\s/g, ''));
            const maxSum = parseFloat(zaim.summMax.replace(/\s/g, ''));
            
            // Проверяем условия из инпутов
            const amountCondition = isNaN(amountNum) || (minSum <= amountNum && maxSum >= amountNum);
            const percentCondition = isNaN(percentNum) || parseFloat(zaim.percent.replace(',', '.')) <= percentNum;
    
            return amountCondition && percentCondition;
        });
    
        // Теперь применяем фильтрацию по чекбоксам
        const isAnyCheckboxChecked = Object.values(selectedOptions).some(option => option);
    
        const finalFiltered = isAnyCheckboxChecked ? filtered.filter(item => {
            return (
                (selectedOptions.mastercard && item.show_mastercard === "1") ||
                (selectedOptions.mir && item.show_mir === "1") ||
                (selectedOptions.visa && item.show_visa === "1") ||
                (selectedOptions.qiwi && item.show_qiwi === "1") ||
                (selectedOptions.yandex && item.show_yandex === "1") ||
                (selectedOptions.cash && item.show_cash === "1")
            );
        }) : filtered;
    
        setFilteredData(finalFiltered); // Обновляем состояние отфильтрованных данных
        sortData(sortType, finalFiltered); // Сортируем уже отфильтрованные данные
    };

    const toggleSortDropdown = () => setSortOpen(!sortOpen);

    const sortData = (type, data) => {
        let sortedData = [...data];

        if (type === 'Сумма по возрастанию') {
            sortedData.sort((a, b) => parseFloat(a.summMax.replace(/\s/g, '')) - parseFloat(b.summMax.replace(/\s/g, '')));
        } else if (type === 'Сумма по убыванию') {
            sortedData.sort((a, b) => parseFloat(b.summMax.replace(/\s/g, '')) - parseFloat(a.summMax.replace(/\s/g, '')));
        } else if (type === 'Проценты по возрастанию') {
            sortedData.sort((a, b) => parseFloat(a.percent.replace(',', '.')) - parseFloat(b.percent.replace(',', '.')));
        } else if (type === 'Проценты по убыванию') {
            sortedData.sort((a, b) => parseFloat(b.percent.replace(',', '.')) - parseFloat(a.percent.replace(',', '.')));
        }

        setFilteredData(sortedData); // Обновляем состояние отфильтрованных данных
    };

    const handleFilterChange = (options) => {
        setSelectedOptions(options);
        applyFilters(options, amount, percent); // обновите фильтрацию после изменения опций
    };

    const handleSort = (type) => {
        setSortType(type);
        sortData(type, filteredData);
        setSortOpen(false); // Сортируем уже отфильтрованные данные
    };

    const applyFilters = (options, amountValue, percentValue) => {
        const amountNum = parseFloat(amountValue.replace(/\s/g, ''));
        const percentNum = parseFloat(percentValue.replace(',', '.'));
    
        // Фильтруем изначальные данные по всем условиям
        const filtered = originalData.filter(item => {
            // Проверка по чекбоксам
            const isChecked =
                (options.mastercard && item.show_mastercard === "1") ||
                (options.mir && item.show_mir === "1") ||
                (options.visa && item.show_visa === "1") ||
                (options.qiwi && item.show_qiwi === "1") ||
                (options.yandex && item.show_yandex === "1") ||
                (options.cash && item.show_cash === "1");
    
            // Фильтрация по инпутам
            const minSum = parseFloat(item.summMin.replace(/\s/g, ''));
            const maxSum = parseFloat(item.summMax.replace(/\s/g, ''));
    
            const amountCondition = isNaN(amountNum) || (minSum <= amountNum && maxSum >= amountNum);
            const percentCondition = isNaN(percentNum) || parseFloat(item.percent.replace(',', '.')) <= percentNum;
    
            // Возвращаем true, если хотя бы один чекбокс выбран и все остальные условия выполняются
            return (isChecked || Object.values(options).every(opt => !opt)) && amountCondition && percentCondition;
        });
    
        setFilteredData(filtered);
    };

    if (loading) return <img src={loadingsvg} alt="Loading" />;
    if (error) return <img src={error404} alt="" />;
    return (
        <div className="zaim">
            <Checkbox selectedOptions={selectedOptions} onFilterChange={handleFilterChange} />
            <div className="container">
                <div className="zaim__wrapper">
                    <h2 className="user">Выбор пользователей:</h2>
                    <div className="zaim__wrapper-buttons">
                        <button >На карту</button>
                        <button>Круглосуточно</button>
                        <button>Без отказа</button>
                        <button>Под залог ПТС</button>
                        <button>Первый без процентов</button>
                        <button>На пол года</button>
                    </div>
                    <div className="zaim__wrapper-inputs">
                        <input
                            type="text"
                            placeholder='Укажите сумму:'
                            value={amount}
                            onChange={handleAmountChange}
                        />
                        <input
                            type="text"
                            placeholder='Укажите срок займа:'
                        />
                        <input
                            type="text"
                            placeholder='Укажите проценты:'
                            value={percent}
                            onChange={handlePercentChange}
                        />
                        <button onClick={handleSearch}>Подобрать займ</button>
                    </div>
                    <div className="sort-container">
                        <h1>Найдено {filteredData.length} предложений</h1>
                        <div className="dropdown">
                            <button onClick={toggleSortDropdown} className="sort-button">
                                {sortType}
                            </button>
                            {sortOpen && (
                                <div className="dropdown-menu">
                                    <button onClick={() => handleSort('Сумма по возрастанию')}>Сумма по возрастанию</button>
                                    <button onClick={() => handleSort('Сумма по убыванию')}>Сумма по убыванию</button>
                                    <button onClick={() => handleSort('Проценты по возрастанию')}>Проценты по возрастанию</button>
                                    <button onClick={() => handleSort('Проценты по убыванию')}>Проценты по убыванию</button>
                                </div>
                            )}
                        </div>
                    </div>
                    {filteredData.map(dat => (
                        <OneZaim key={dat.id} dat={dat} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Zaim;