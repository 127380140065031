import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header/header';
import Promo from './components/promo/promo';
import Credit from './components/credit/credit'; 
import CreditCard from './components/creditCard/creditCard';
import Loans from './components/loans/loans';
import HomePage from './components/homePage/homePage';
import Articles from './components/articles/articles';
import Questions from './components/questions/questions';
import Zaim from './components/zaim/zaim';

function App() {
  return (
    <div className="App">
      <Header/>
      <Promo/>
      <div className="container__flex">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/credit-card" element={<CreditCard />} />
          <Route path="/debit-card" element={<Credit />} />
          <Route path="/loans" element={<Loans />} />
          <Route path="/zaim" element={<Zaim />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/questions" element={<Questions />} />
        </Routes>
      </div>
    </div>
  );
}

// Оборачивание App в Router с флагом future
const AppWrapper = () => (
  <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
    <App />
  </Router>
);

export default AppWrapper;