import './promo.scss';

const Promo = () => {
    return (
        <section className="promo">
            <div className="container">
                <div className="promo__wrapper">
                    <h1>Оформите лучшие займы в Казани до 100000 рублей <span>на карту не выходя из дома</span></h1>
                    <h2>Здесь собраны самые популярные российские МФО. Они принимают заявки онлайн и выдают деньги в течение часа. Получение микрозайма круглосуточное.</h2>
                </div>
            </div>
        </section>
    )
}

export default Promo;